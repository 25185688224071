import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiUrls, get, post } from 'utils/request';
import moment from 'moment';
import axios from 'axios';

const initialState = {
  isLoading: false,
  error: null,
  errorMessages: '',
  date: moment().format('YYYY-MM'),
  romListContractor: [],
};

const removeNullValues = (obj) => {
  const entries = Object.entries(obj);
  const filteredEntries = entries.filter(([key, value]) => value !== null);

  return Object.fromEntries(filteredEntries);
};

export const getCrusherPlan = createAsyncThunk(
  "crusherPlan/get",
  async (params, thunkAPI) => {
    const payload = {
      columns: [],
      start: 0,
      draw: 1,
      length: 500,
      ...params,
    }
    const response = await axios.post(`${apiUrls.crushers}/filter`, payload);
    return response.data;
  }
);


export const getListContractorRomLocation = createAsyncThunk(
  'DashboardRomExcavator/getListContractorRomLocation',
  async (params) => {
    const { contractor, search } = params

    const response = await get(`${apiUrls.romExcavatorConfiguration}?contractorName=${contractor}&search=${search}`);
    //console.log("params", params)

    return response.data;
  }
);



const generateResultArray = (response = [], date, metrics = 'actual') => {
  const daysInMonth = moment(date, 'YYYY-MM').daysInMonth();
  const resultArray = new Array(daysInMonth).fill(0);
  const resultEwh = new Array(daysInMonth).fill(0);
  const resultProduction = new Array(daysInMonth).fill(0);
  if (metrics === 'actual') {
    response.forEach(item => {
      const index = moment(item.date).format("DD") - 1;
      resultArray[index] += item.production;
    });
  } else if (metrics === 'plan' && response.length > 0) {
    let value = 0
    for (const item of response) {
      value += item.value
    }
    const planValues = value;
    return new Array(daysInMonth).fill(planValues);
  } else if (metrics === 'productivity' && response.length > 0) {
    let value = 0
    for (const item of response) {
      value += item.productivity_target
    }
    const planValues = value;
    return new Array(daysInMonth).fill(planValues);
  } else if (metrics === 'productivityData') {
    response.forEach(item => {
      const index = moment(item).format("DD") - 1;
      resultProduction[index] += item.production !== undefined ? item.production : 0;
      resultEwh[index] += item.ewh !== undefined ? item.ewh : 0;
    });
    resultProduction.forEach((item, index) => {
      if (item === 0 || item === undefined || item === null) {
        resultArray[index] = 0
      } else {
        resultArray[index] = item / resultEwh[index];
      }
    });
  }

  return resultArray;
};

const cumulativeSum = (arr) => {
  let result = [];
  let sum = 0;
  arr.forEach((num) => {
    sum += num;
    result.push(sum);
  });
  return result;
};

const DashboardProductionRomExcavatorSlice = createSlice({
  name: 'DashboardProductionRomExcavator',
  initialState,
  reducers: {
    resetState: (state) => {
      state.data = {
        dataActual: [],
        dataPlan: [],
      };
      state.isLoading = false;
      state.error = null;
      state.errorMessages = '';
    },
    resetDate: (state) => {
      state.date = moment().format('YYYY-MM');
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },

    contractorRomList: (state, action) => {

      const payload = action.payload;

      // const filterRomList = payload.filter((item) => item.id)

      // const filterContractor = state.pitListContractor.contractor.filter(
      //   (item) => item.contractor.id === action.payload?.value.id
      // );

      // let contractorPitList = [];

      // state.pitListContractor.pitList.map((e) => {
      //   filterContractor.map((val) => {
      //     if (e.id === val.location.id)
      //       return contractorPitList.push(val.location);
      //   });
      // });
      // state.pitListContractor.contractorPit = contractorPitList;
    },
  },
  extraReducers: {
    [getListContractorRomLocation.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.errorMessages = '';
    },
    [getListContractorRomLocation.fulfilled]: (state, action) => {

      state.isLoading = false;
      state.error = null;
      state.errorMessages = '';
      state.romListContractor = action.payload
    },
    [getListContractorRomLocation.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.errorMessages = action.error.message;
    },
  }
});

export const { actions } = DashboardProductionRomExcavatorSlice;
export const { resetState, resetDate, setDate, contractorRomList } = actions;
export const dashboardProductionRomExcavatorSelector = (state) => state.dashboardProductionRomExcavator;
export default DashboardProductionRomExcavatorSlice.reducer;
